<template>
  <div id="indexThree">

    <div v-html="richTextContent" style="width:90%;margin:0 auto"></div>
  </div>
</template>

<script>
import {
    dcShoppingmallCreationcenterDetail
} from "@/api/Introduction/index.js";
import { mapGetters } from "vuex";
export default {
  name: "GoodsHome",
  components: {},
  data() {
    return {
        richTextContent:''
    };
  },
  computed: {
    ...mapGetters({
      lange: "user/lange",
      organizationName: "user/organizationName",
      priceChangeList: "user/priceChangeList",
    }),
  },
  created() {
    dcShoppingmallCreationcenterDetail({pageNum:1,pageSize:10}).then(res=>{
       this.richTextContent = res.data.list[0].content

    })
  },
  
  watch: {},
 
  methods: {
    
  },
};
</script>
<style>
.btnMore {
  line-height: 28px;
  text-align: center;
  color: black;
  border: 1px solid black;
  box-sizing: border-box;
  border-radius: 30px;
  margin: 70px auto 70px auto;
  display: block;
  line-height: 45px;
  font-size: 14px;
  font-weight: 700;
  width: 200px;
  &:hover {
    color: #fff;
    background: black;
  }
}
.new_goods_title_hots {
  height: 40px;

  margin-top: 140px;
  margin-bottom: 140px;

  span {
    font-size: 40px;
    line-height: 28px;

    font-weight: bold;
  }
}
</style>
<style lang="scss" scoped>
.el-men u-vertical-demo:not(.el-menu--collapse) {
  width: 154px;
  min-height: 400px;
}
html {
  font-size: 16px; /* 默认字体大小 */
  @media only screen and (max-width: 1200px) {
    font-size: 12px; /* 中等屏幕 */
  }
  @media only screen and (max-width: 768px) {
    font-size: 9px; /* 中等屏幕 */
  }
  @media only screen and (max-width: 480px) {
    font-size: 6px; /* 中等屏幕 */
  }
}
#indexThree {
  .header_wrap {
    min-height: 100%;

    .main {
      // padding-bottom: 200px;
      .header {
        .top_header {
          background: #f5f5f5;
          border-bottom: #eaeaea;
          a {
            color: #999;
            &:hover {
              color: #333;
            }
          }
          .wrap {
            width: 1400px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 39px;
            color: #999;
            font-size: 12px;
          }
          .top_right {
            li {
              padding: 0 15px;
              border-right: 1px solid #e1e1e1;
              display: inline-block;
              cursor: pointer;
            }
          }
        }
        .center_header {
          height: 125px;
          width: 1400px;
          // width: 1265px;
          .logo {
            width: 220px;
            height: 105px;
            margin-top: 10px;
          }
          .nav {
            margin-left: 50px;
            ul {
              float: left;
              // width: 688px;
              width: 610px;
              display: block;
              margin-top: 50px;
              margin-right: 30px;
            }
            li {
              float: left;
              margin: 0 4px;
              padding: 0 4px;
              font-size: 16px;
              color: #999;
              &:hover {
                cursor: pointer;
                color: #f80;
              }
            }
            .router-link-exact-active {
              color: #f80;
            }
          }
          .head_menu_user {
            margin-top: 50px;
            img {
              margin-right: 10px;
            }
            .info {
              color: #999;
              line-height: 25px;
              font-size: 14px;
              .red {
                color: #f80;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-flex;
                max-width: 90px;
              }
              .logout {
                margin-left: 15px;
                cursor: pointer;
              }
            }
          }
        }
      }
      .login_header {
        padding-top: 30px;
        .login_img {
          display: flex;
          align-items: center;
          div:hover {
            cursor: pointer;
          }
          p {
            color: #8c8c8c;
            margin-left: 15px;
          }
        }
        .login_text {
          line-height: 130px;
          font-size: 14px;
          a {
            color: #f80;
          }
        }
      }
    }
  }
  .footer_wrap {
    height: 200px;

    padding-top: 30px;
    ul {
      li {
        display: inline-block;
        width: 270px;
        height: 56.5px;
        img {
          width: 74px;
          margin-top: -7px;
        }
        .info {
          display: inline-block;
          margin-left: 20px;
          vertical-align: top;
          .title {
            color: #333;
            font-size: 16px;
            font-weight: bold;
            line-height: 36px;
          }
          .desc {
            font-size: 12px;
            color: #999;
          }
        }
        &:not(:first-child) {
          margin-left: 33px;
        }
      }
    }
    .copyright {
      text-align: center;
      font-size: 12px;
      color: #666;
      margin-top: 48px;
      clear: both;
      .jc {
        line-height: 50px;
      }
      .link {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        p {
          display: flex;
          flex-wrap: wrap;
        }
        a {
          margin-right: 6px;
          white-space: nowrap;
        }
      }
    }
  }
  .banner {
    position: relative;
    cursor: pointer;
  }

  .banner_sub_width {
    height: 400px;

    .banner_sub {
      width: 320px;
      margin-top: 10px;
      height: 400px;
      background: #fff;
      border-radius: 4px;
    }

    .login_avatar {
      color: #999;
      text-align: center;
      font-size: 19px;

      img {
        border-radius: 50%;
        margin-bottom: 10px;
        margin-top: 10px;
        width: 80px;
        height: 80px;
      }
    }

    .to_user_center {
      width: 220px;
      padding: 48px 0;

      span {
        background: #f80;
        color: #fff;
        line-height: 30px;
        width: 360px;
        text-align: center;
        border-radius: 4px;
        margin: 0 auto;
        display: block;
        cursor: pointer;
      }
    }

    .login_btn {
      margin-top: 10px;

      .left_login {
        width: 83px;
        line-height: 31px;
        font-size: 14px;
        border: 1px solid #f80;
        border-radius: 3px;
        margin-left: 28px;
        text-align: center;

        &:hover {
          cursor: pointer;
        }

        &:first-child {
          color: #f80;

          &:hover {
            background: #f80;
            color: #fff;
          }
        }

        &:last-child {
          background: #f80;
          color: #fff;
        }
      }
    }

    .sub_gg {
      padding: 8px 25px;
      box-sizing: border-box;
      margin-top: 10px;
      border-top: 1px solid #f1f1f1;
      font-size: 12px;

      h3 {
        font-size: 14px;
        margin-bottom: 5px;
      }

      li {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 25px;
        color: #999;
      }
    }

    .other_block {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .block {
        border-top: 1px solid #dedede;
        float: left;
        width: 140px;
        height: 73px;
        background: #fbfbfb;
        box-sizing: border-box;
        font-size: 14px;
        text-align: center;

        &:first-child,
        &:nth-child(3) {
          border-right: 1px solid #dedede;
        }

        img {
          width: 30px;
          height: 30px;
          margin-top: 8px;
          margin-bottom: 5px;
        }

        &:hover {
          background: #eaeaea;
          cursor: pointer;
        }
      }
    }
  }

  .main {
    .advertising {
      display: flex;
      margin-top: 10px;

      div {
        flex: 1;

        img {
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }

    .recommend {
      margin-top: 20px;

      .item {
        // height: 510px;
        margin-left: 20px;
        margin-bottom: 12px;
        width: 21%;

        .recommend_left {
          height: 280px;
          width: 360px;

          img {
            width: 400px;
            height: 320px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        &:nth-child(1),
        &:nth-child(4n + 1) {
          margin-left: 0;
        }

        dl {
          width: 100%;
          // border: 1px solid #eee;
          .slide-enter-active,
          .slide-leave-active {
            transition: transform 0.5s;
          }

          .slide-enter,
          .slide-leave-to {
            transform: translateX(100%);
          }
          // &:hover {
          //   cursor: pointer;
          //   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
          //   width: 100%;
          // }
          .image-transitions {
            transition: transform 0.5s ease;
          }

          // .image-transition-enter {
          //   transform: translateX(-100%);
          // }

          // .image-transition-enter-to {
          //   transform: translateX(0);
          // }

          // .image-transition-leave {
          //   transform: translateX(0);
          // }

          // .image-transition-leave-to {
          //   transform: translateX(100%);
          // }
          img {
            width: 100%;
          }

          dd {
            padding: 0 5px;
          }

          .title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 15px;
            font-weight: 600;
            color: black;
            line-height: 36px;
            margin-top: 25px;
          }

          .other {
            .price {
              font-size: 17px;
              color: black;
              font-weight: 600;
            }

            .wei {
              font-size: 14px;
              color: #666;
            }
          }

          .btn {
            line-height: 28px;
            text-align: center;
            color: black;
            border: 1px solid black;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border-radius: 30px;
            /* margin: 6px auto 10px auto; */
            display: block;
            line-height: 20px;
            font-size: 14px;
            font-weight: 700;
            padding: 2px 10px;
            &:hover {
              color: #fff;
              background: black;
            }
          }
          .btns {
            line-height: 28px;
            text-align: center;
            color: black;
            border: 1px solid black;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border-radius: 30px;
            /* margin: 6px auto 10px auto; */
            display: block;
            line-height: 20px;
            // font-size: 14px;
            font-weight: 700;
            padding: 2px 17px;
            &:hover {
              color: #fff;
              background: black;
            }
          }
        }
      }
      .itemShop {
        margin-left: 20px;
        margin-bottom: 12px;
        width: 45%;
        .recommend_left {
          height: 280px;
          width: 360px;

          img {
            width: 800px;
            height: 320px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        // &:nth-child(1),
        // &:nth-child(4n + 1) {
        //   margin-left: 0;
        // }

        dl {
          width: 100%;
          // border: 1px solid #eee;

          // &:hover {
          //   cursor: pointer;
          //   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
          //   width: 440px;
          // }

          img {
            width: 100%;
          }

          dd {
            padding: 0 5px;
          }

          .title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 15px;
            font-weight: 700;
            color: black;
            line-height: 36px;
            margin-top: 25px;
          }

          .other {
            .price {
              font-size: 17px;
              color: black;
              font-weight: 600;
            }

            .wei {
              font-size: 14px;
              color: #666;
            }
          }

          .btn {
            line-height: 28px;
            text-align: center;
            color: black;
            border: 1px solid black;
            box-sizing: border-box;
            border-radius: 30px;
            margin: 6px auto 10px auto;
            display: block;
            line-height: 20px;
            font-size: 14px;
            font-weight: 700;
            &:hover {
              color: #fff;
              background: black;
            }
          }
        }
      }
      .items {
        height: 470px;
        margin-left: 10px;
        margin-bottom: 12px;

        .recommend_left {
          height: 280px;
          width: 360px;

          img {
            width: 360px;
            height: 280px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        &:nth-child(1),
        &:nth-child(3n + 1) {
          margin-left: 0;
        }

        dl {
          width: 360px;
          // border: 1px solid #eee;

          &:hover {
            cursor: pointer;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
          }

          img {
            width: 360px;
            height: 360px;
          }

          dd {
            padding: 0 5px;
          }

          .title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 15px;
            font-weight: 700;
            color: black;
            line-height: 36px;
            margin-top: 25px;
          }

          .other {
            .price {
              font-size: 18px;
              color: black;
            }

            .wei {
              font-size: 14px;
              color: #666;
            }
          }

          .btn {
            width: 243px;
            line-height: 28px;
            text-align: center;
            color: #f80;
            border: 1px solid #f80;
            box-sizing: border-box;
            border-radius: 4px;
            margin: 6px auto 10px auto;
            display: block;
            height: 28px;
            font-size: 14px;

            &:hover {
              color: #fff;
              background: #f80;
            }
          }
        }
      }

      .null {
        width: 188px;
        border: 1px solid #eee;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .new_goods_title_hot {
      height: 40px;

      margin-top: 140px;
      margin-bottom: 140px;

      span {
        font-size: 40px;
        line-height: 28px;

        font-weight: bold;
        font-size: 40px;
      }
    }

    .new_goods_title_new {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;

      span {
        font-size: 40px;
        line-height: 28px;
        border-left: 5px solid #f80;

        font-weight: bold;
        font-size: 40px;
      }
    }

    .new_goods_title_new1 {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;

      span {
        font-size: 40px;
        line-height: 28px;
        border-left: 5px solid #f80;

        font-weight: bold;
        font-size: 40px;
      }
    }

    .new_goods_title_new2 {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;

      span {
        font-size: 40px;
        line-height: 28px;
        border-left: 5px solid #f80;

        font-weight: bold;
        font-size: 40px;
      }
    }

    .new_goods_title_Image_store {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;

      span {
        font-size: 20px;
        line-height: 28px;
        border-left: 5px solid #f80;

        font-weight: bold;
        font-size: 20px;
      }
    }

    .section4 {
      position: relative;
      text-align: center;
      margin-bottom: 25px;
      background: #fff;

      & > span {
        position: relative;
        display: inline-block;
        margin: 25px auto;
        font-weight: 700;
        font-size: 22px;

        &::after {
          content: "";
          position: absolute;
          bottom: -10px;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          display: inline-block;
          background: -webkit-gradient(
            linear,
            left top,
            right top,
            from(rgba(255, 166, 0, 0.929412)),
            to(#ff6a00)
          );
          background: linear-gradient(90deg, rgba(255, 166, 0, 0.929412), #ff6a00);
          width: 40%;
          border-radius: 50px;
          height: 4px;
        }
      }

      .article_list {
        text-align: left;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: wrap;

        .article_item {
          border: 1px solid #eee;
          padding: 10px 30px;
          margin: 10px 0;
          width: 44%;
          cursor: pointer;

          &:hover {
            box-shadow: 0 0 5px 1px #dcdfe6;

            .title a {
              color: #ff6a00;
            }
          }

          .title {
            display: -webkit-box;
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            margin-bottom: 10px;
          }

          .summary {
            color: #767676;
            font-size: 14px;
          }

          .title a {
            font-size: 18px;
            color: #333;
          }

          .title {
            p {
              color: #999;
            }
          }
        }
      }
    }
  }

  .xian {
    height: 5px;
    background: #eee;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
</style>
